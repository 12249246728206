import React, { useState } from "react"
import { Button, Modal, Form, Alert } from "react-bootstrap"

const CourseForm = props => {
  const {
    setCourseModalShow,
    downloadSyllabus,
    setDownloadSyllabusModal,
    course,
    brochureURL, utm
  } = props
  const [success, setSuccess] = useState(false)
  const [failure, setFailure] = useState(false)
  
  const [formDatas, setFormDatas] = useState({
    courseName: null,
    courseEmail: null,
    courseNumber: 0,
    // courseCity: null
  })

  const [validation, setValidation] = useState({
    courseErrName: null,
    courseErrEmail: null,
    courseErrNumber: null,
    // courseCity: null
  })

  const handleCloseModal = () => {
    if (downloadSyllabus) {
      setDownloadSyllabusModal(false)
    } else {
      setCourseModalShow(false)
    }
  }

  const handleChangeCourseName = event => {
    setValidation(prevState => {
      return { ...prevState, courseErrName: null }
    })

    let currentValue = event.target.value
    setFormDatas(prevState => {
      return { ...prevState, courseName: currentValue }
    })
  }

  const handleChangeCourseEmail = event => {
    setValidation(prevState => {
      return { ...prevState, courseErrEmail: null }
    })

    let currentValue = event.target.value
    setFormDatas(prevState => {
      return { ...prevState, courseEmail: currentValue }
    })
  }

  const handleChangeCourseNumber = event => {
    setValidation(prevState => {
      return { ...prevState, courseErrNumber: null }
    })

    let currentValue = event.target.value
    setFormDatas(prevState => {
      return { ...prevState, courseNumber: currentValue }
    })
  }

  // const handleChangeCourseCity = (event) => {

  //   setValidation((prevState) => {
  //     return { ...prevState, courseErrCity: null };
  //   });

  //   let currentValue = event.target.value;
  //   setFormDatas((prevState) => {
  //     return { ...prevState, courseCity: currentValue };
  //   });
  // }

  const validateForm = () => {
    setSuccess(false)
    setFailure(false)

    if (!formDatas.courseName) {
      setValidation(prevState => {
        return { ...prevState, courseErrName: "Please enter your name" }
      })
    } else {
      setValidation(prevState => {
        return { ...prevState, courseErrName: null }
      })
    }

    if (
      !formDatas.courseEmail ||
      !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        formDatas.courseEmail
      )
    ) {
      setValidation(prevState => {
        return { ...prevState, courseErrEmail: "Please enter a valid Email" }
      })
    } else {
      setValidation(prevState => {
        return { ...prevState, courseErrEmail: null }
      })
    }

    if (formDatas.courseNumber.length !== 10) {
      setValidation(prevState => {
        return { ...prevState, courseErrNumber: "Please enter a valid number" }
      })
    } else {
      setValidation(prevState => {
        return { ...prevState, courseErrNumber: null }
      })
    }

    // if(formDatas.courseCity === null) {
    //   setValidation((prevState) => {
    //     return { ...prevState, courseErrCity: 'Please select a city from the drop down' }
    //   });
    // } else {
    //   setValidation((prevState) => {
    //     return { ...prevState, courseErrCity: null };
    //   });
    // }

    if (
      validation.courseErrName === null &&
      validation.courseErrEmail === null &&
      validation.courseErrNumber === null &&
      formDatas.courseName !== "" &&
      formDatas.courseEmail !== "" &&
      formDatas.courseNumber.length === 10
    ) {
      // console.log("Post to API now!");
      document.getElementById("enquireNow").innerHTML = "Please wait..."
      const opts = {
        Name: formDatas.courseName,
        EmailID: formDatas.courseEmail,
        MobileNumber: formDatas.courseNumber,
        Course: [course.Title],
        EmailTemplate: "CourseTemplate",
        ReferenceId: course.Id,
        ReferenceType: "Course",
        PageURL: course.SlugURL,
        Source: utm.utmSource,
        Medium: utm.utmMedium,
        Campaign: utm.utmCampaign,
      }

      fetch(
        "https://gsq4d48100.execute-api.ap-south-1.amazonaws.com/dev/leads/create-lead",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(opts),
        }
      )
        .then(function(response) {
          return response.json()
        })
        .then(function(data) {
          if (data.success) {
            setSuccess(true)
            if (downloadSyllabus) {
              document.getElementById("enquireNow").innerHTML =
                "Download Brochure"
            } else {
              document.getElementById("enquireNow").innerHTML = "Enquire"
            }

            setFormDatas({
              courseName: "",
              courseEmail: "",
              courseNumber: 0,
            })
          } else {
            setFailure(true)
            if (downloadSyllabus) {
              document.getElementById("enquireNow").innerHTML =
                "Download Brochure"
            } else {
              document.getElementById("enquireNow").innerHTML = "Enquire"
            }
          }
        })
    }
  }

  return (
    <Modal
      id="course-enquiry-form"
      show={true}
      onHide={handleCloseModal}
      animation={true}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Button className="close" onClick={handleCloseModal}>
        <span aria-hidden="true">×</span>
      </Button>
      <Modal.Header>
        <Modal.Title>
          <img
            src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/logos/crampete-c-logo.png"
            alt="Crampete"
          />
          Let's Get Started!
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {success && !downloadSyllabus && (
          <Alert
            variant="success"
            style={{ fontSize: "14px", textAlign: "center" }}
          >
            Thanks, we've received your enquiry!
          </Alert>
        )}

        {success && downloadSyllabus && (
          <Alert
            variant="success"
            style={{ fontSize: "14px", textAlign: "center" }}
          >
            Thanks, please click{" "}
            <a href={brochureURL} target="_blank" rel="noopener noreferrer">
              here
            </a>{" "}
            to download your eBook
          </Alert>
        )}

        {failure && (
          <Alert
            variant="danger"
            style={{ fontSize: "14px", textAlign: "center" }}
          >
            Oops, something went wrong!
          </Alert>
        )}
        <Form>
          <Form.Group controlId="formBasicName">
            <Form.Control
              type="text"
              name="courseName"
              onChange={handleChangeCourseName}
              placeholder="Name"
              value={formDatas.courseName}
            />
            <Form.Text className="text-danger">
              {validation.courseErrName}
            </Form.Text>
          </Form.Group>
          <Form.Group controlId="formBasicEmail">
            <Form.Control
              type="email"
              name="courseEmail"
              onChange={handleChangeCourseEmail}
              placeholder="Email"
              value={formDatas.courseEmail}
            />
            <Form.Text className="text-danger">
              {validation.courseErrEmail}
            </Form.Text>
          </Form.Group>
          <Form.Group controlId="formBasicNumber">
            <Form.Control
              type="number"
              name="courseNumber"
              onChange={handleChangeCourseNumber}
              placeholder="Phone Number"
              value={formDatas.courseNumber === 0 ? "" : formDatas.courseNumber}
            />
            <Form.Text className="text-danger">
              {validation.courseErrNumber}
            </Form.Text>
          </Form.Group>
          {/* <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Control as="select" name="courseCity" onChange={handleChangeCourseCity} value={formDatas.courseCity}>
                  <option selected disabled>Select City</option>
                  <option value="Chennai">Chennai</option>
                  <option value="Bangalore">Bangalore</option>
                  <option value="Coimbatore">Coimbatore</option>
                  <option value="Raipur">Raipur</option>
                  <option value="Bhubaneswar">Bhubaneswar</option>
                  <option value="Singapore">Singapore</option>
                  <option value="Malaysia">Malaysia</option>
                  <option value="Others">Others</option>
                </Form.Control>
                <Form.Text className="text-danger">{validation.courseErrCity}</Form.Text>
              </Form.Group> */}
          <Form.Group className="text-center">
            <Button variant="primary" id="enquireNow" onClick={validateForm}>
              {downloadSyllabus ? "Download Brochure" : "Enquire"}
            </Button>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default CourseForm