import React from "react"
import Layout from "../components/layout"
import Jumbotron from 'react-bootstrap/Jumbotron'
import Coursecard from "../components/cramcode-cards";

const Cramcode = ({ data }) => {
  return (
    <Layout>
    <Jumbotron className="bg-lightblue">
      <div class="container">
        <div style={{maxWidth:"600px",textAlign:"center",margin:"auto"}}>
          <h2>Cram Code</h2>
          <p className="mt-4">
          Dealing with irregular work or college schedules, but still like to upgrade your skills? Enrol in our in-demand self-paced courses and reach new career-highs.
          </p>
        </div>
      </div>
    </Jumbotron>
      <div class="container">
        <div className="row">
          <div class="col-lg-12">
            <div class="emply-text-sec">
              <Coursecard></Coursecard>
            </div>
        </div>
        </div>
      </div>
      <hr className="container hrLight mt-4" />
    </Layout>
  )
}

export default Cramcode
