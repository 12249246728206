import React, { useState } from "react";
import {Card, Button } from 'react-bootstrap';
import { useStaticQuery, graphql, Link} from 'gatsby';
import CourseForm from '../components/CourseForm'

const Coursecard = () => {
  
	const data = useStaticQuery(graphql`
    query {
			allCourseDataset(filter: {Type: {eq: "Cram Code"}}, sort: {fields: OrderNumber}) {
				edges {
					node {
            Id
            Name
            SlugURL
            ThumbnailURL
            Duration
            Description
					}
				}
			}
		}
  `);
  
  const [courseModalShow, setCourseModalShow] = useState(false);
  
  const [course, setCourse] = useState(
    {
      Title: null,
      SlugURL: null,
      Id: null
    }
  )


  const queryString = global.window?.location.search
  const urlParams = new URLSearchParams(queryString)

  const [utmParams, setUtmParams] = useState({
    utmSource: urlParams.get("utm_source") || "",
    utmMedium: urlParams.get("utm_medium") || "",
    utmCampaign: urlParams.get("utm_campaign") || "",
  })
  

  const handleCourseModal = (title, slugurl, id, e) => {   
    // debugger
    setCourseModalShow (!courseModalShow)

    setCourse((prevState) => {
      return { ...prevState, Title : title, SlugURL: slugurl, Id: id  };
    });
  }

	return (
		<div class="row">
			{data.allCourseDataset.edges.map(({ node }) => {
        
        const buttonType = <Button className="outline-secondary" onClick={handleCourseModal.bind(this, node.Name, node.SlugURL, node.Id)}>Read more</Button>;
        const textType = <Link to={"/courses/"+node.SlugURL} className="outline-secondary btn btn-primary">Read more</Link>;
        let resp;

        switch(node.SlugURL)
        {
          case "python-online-course":
          resp = textType
          break;
          
          case "reactjs-online-course":
          resp = textType
          break;

          case "mongodb-online-course":
          resp = textType
          break;

          case "php-online-course":
          resp = textType
          break;

          default:
          resp = buttonType  
        }
        
        

				return (
          <div class="col-md-4 col-lg-3 col-xl-3" style={{ marginBottom: '20px' }}>
            <Card className="cardShadow" data-id={node.SlugURL}>
							<Card.Img variant="top" src={node.ThumbnailURL} />
              <Card.Body>
                <Card.Title style={{clear:"both"}}>{node.Name}</Card.Title>
                <Card.Text className="five-line-clamp">
									<div
	                  dangerouslySetInnerHTML={{ __html: node.Description }}
	                />
                </Card.Text>
              </Card.Body>
              <Card.Body>
                {resp}
              </Card.Body>
            </Card>
        </div>
        )
			})}
      {courseModalShow && <CourseForm utm={utmParams} course={course} setCourseModalShow={setCourseModalShow} />}
		</div>
	);
};

export default Coursecard;
